var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogRpoertStatusManage__Component" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("ניהול התראות")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogRpoertStatusManage__Component Table" },
          [
            _vm._m(0),
            _vm._l(_vm.model, function(item, index) {
              return _c("div", { key: item.Date, staticClass: "row" }, [
                _c("div", [_vm._v(_vm._s(item.Name))]),
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "removeItem",
                      attrs: { href: "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeItem(index)
                        }
                      }
                    },
                    [_vm._v("הסר")]
                  )
                ])
              ])
            })
          ],
          2
        ),
        !_vm.model.length
          ? _c("h1", { staticClass: "no-records" }, [_vm._v("אין התראות לדוח")])
          : _vm._e()
      ]),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "div",
          [
            _vm.removedItems.length
              ? _c("b-button", {
                  attrs: {
                    loading: _vm.isLoading,
                    label: "שמור",
                    type: "is-primary"
                  },
                  on: { click: _vm.save }
                })
              : _vm._e(),
            _c("b-button", {
              attrs: { disabled: _vm.isLoading, label: "סגור" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v("סטטוס")]),
      _c("div", { staticClass: "column", staticStyle: { width: "50px" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }