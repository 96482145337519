<template>
    <span class="Field__Component_Inline" dir='ltr'>
        {{date}}
    </span>
</template>

<script>
import moment from 'moment';

export default {
    name: 'FieldDateHourInline',
    props: ['value'],
    computed: {
        date() {
            if (this.value) {
                return moment(this.value).format('DD/MM/YYYY HH:mm');
            }
            return null;
        },
    },
};
</script>

<style scoped lang="scss">
    .Field__Component_Inline {
        padding-right: 5px;
    }
</style>
