<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול חוזים</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="scollable">
          <div class="DialogRpoertStatusManage__Component Table">
            <div class="columns">
              <div class="column" style="width: 200px">לקוח</div>
              <div class="column" style="width: 100px">מתאריך</div>
              <div class="column" style="width: 200px">עד תאריך</div>
              <div class="column" style="width: 50px"></div>
            </div>
            <div class="row" v-for="(item, index) in model" :key="item.Id">
              <div class="Table_Field">{{ item.Profile.Name }}</div>
              <div class="Table_Field datetime_format">
                <FieldDateHourInline :value="item.StartDate" />
              </div>
              <div class="Table_Field datetime_format">
                <FieldDateHourInline :value="item.EndDate" />
              </div>
              <div>
                <a class="removeItem" href="" @click.prevent="removeItem(index)">הסר</a>
              </div>
            </div>

            <div class="row" v-for="(item, index) in $v.newItemModel.$each.$iter" :key="index">
              <div class="Table_Field" :class="{ invalid: item.ProfileId.$error }">
                <v-select dir="rtl" :getOptionLabel="(item) => `${item.IdNumber} - ${item.Text}`" :options="users"
                  :clearable="true" placeholder="בחר פרופיל" :reduce="(item) => item.Value"
                  v-model="item.ProfileId.$model">
                  <div slot="no-options">לא נמצאו ערכים דומים</div>
                </v-select>
              </div>
              <div class="Table_Field" :class="{ invalid: item.StartDate.$error }">
                <b-datetimepicker locale="en-GB" editable :datepicker="{ monthNames, dayNames }" icon="calendar-today"
                  icon-prev="chevron-right" icon-next="chevron-left" v-model="item.StartDate.$model" />
              </div>
              <div class="Table_Field" :class="{ invalid: item.EndDate.$error }">
                <b-datetimepicker locale="en-GB" v-model="item.EndDate.$model" editable
                  :datepicker="{ monthNames, dayNames }" icon-prev="chevron-right" icon-next="chevron-left"
                  icon="calendar-today" />
              </div>
              <div>
                <a class="removeItem" href="" @click.prevent="removeNewItem(index)">הסר</a>
              </div>
            </div>
          </div>
        </div>
        <h1 class="no-records" v-if="!model.length && !newItemModel.length">
          הוסף שורה כדי להוסיף חוזה לרכב
        </h1>
      </section>
      <footer class="modal-card-foot">
        <div>
          <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
          <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
        </div>
        <div>
          <a href="" @click.prevent="addRow">הוסף שורה</a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
// import ReportService from '@/services/ReportService';
import ProfileService from "@/services/ProfileService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import store from "../../store";
import VehicleService from "../../services/VehicleService";
import FieldDateHourInline from "../Fields/FieldDateHourInline.vue";

export default {
  name: "VehicleAddContract",
  components: {
    vSelect,
    FieldDateHourInline,
  },
  computed: {
    Id: () => store.state.vehicle.Id,
    VehicleContracts: () => store.state.vehicle.VehicleContracts,
    isAdmin: () => store.state.auth?.user?.claims?.isAdmin === "True",
  },
  validations: {
    newItemModel: {
      $each: {
        ProfileId: {
          required,
        },
        StartDate: {
          required,
        },
        EndDate: {
          required,
        },
      },
    },
  },
  data() {
    return {
      isLoading: false,
      model: [],
      users: [],
      newItemModel: [],
      reports: [],
      monthNames: [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר",
      ],
      dayNames: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    };
  },
  async created() {
    if (this.isAdmin) {
      const { data } = await ProfileService.all(false);
      this.users = data.map((i) => ({
        Text: i.Name,
        IdNumber: i.IdNumber,
        Value: i.Id,
      }));
    } else {
      this.users = await LeasingCompaniesService.get();
    }
    if (this.VehicleContracts) {
      this.model = this.VehicleContracts.slice(0);
    }
  },
  methods: {
    addRow() {
      this.newItemModel.push({
        ProfileId: null,
        StartDate: null,
        EndDate: null,
      });
    },
    getDateFormat(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DDTHH:mm");
      }
      return null;
    },
    removeItem(index) {
      VehicleService.deleteContract(this.Id, this.model[index].Id);
      this.model.splice(index, 1);
    },
    removeNewItem(index) {
      this.newItemModel.splice(index, 1);
    },
    setSelected(value) {
      this.statusId = value;
    },
    save() {
      // ReportService.search({
      //   PageSize: 20,
      //   IsAscending: false,
      //   ResultType: 3,
      //   SortedBy: "LastPayDate",
      //   PageNumber: 1,
      //   TransferStatud: [14]
      // }).then((res) => {
      //   this.reports = res.data.Items.map((r) => ({ ReportNumber: r.ReportNumber, Id: r.Id }));
      //   Dialog.confirm({
      //     message: `</div>האם ברצונך להפיק מסמכי הסבה לדוחות:<br>${this.reports.map((r) => `מספר דוח:${r.ReportNumber}<br>`).join('')}<div>`,
      //     onConfirm: () => {
      //       const loading = Loading.open();
      //       LeasingCompaniesService
      //         .transferReports(this.reports.map((r) => r.Id))
      //         .then(() => {
      //           Toast.open({
      //             type: 'is-success',
      //             message: 'הפעולה בוצעה!',
      //             duration: 4000,
      //           });
      //         })
      //         .catch(() => {
      //           Toast.open({
      //             type: 'is-danger',
      //             message: 'לא היה ניתן לבצע את הפעולה!',
      //             duration: 8000,
      //           });
      //         })
      //         .finally(() => {
      //           loading.close();
      //         });
      //     },
      //     confirmText: 'בטוח!',
      //     cancelText: 'בטל',
      //   });
      // });
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      if (!this.newItemModel.length) {
        store.commit("vehicle/setVehicleContracts", this.model);
        this.$emit("close");
        return;
      }

      const reqs = this.newItemModel.map((i) =>
        VehicleService.addContract(this.Id, {
          ProfileId: i.ProfileId,
          StartDate: this.getDateFormat(i.StartDate),
          EndDate: this.getDateFormat(i.EndDate),
        })
      );
      Promise.all(reqs)
        .then((r) => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit(
            "vehicle/setVehicleContracts",
            r.map((i) => i.data).concat(this.model)
          );
        })
        .catch((err) => {
          if (err.response?.data?.ErrorCode === 5) {
            Toast.open({
              position: "is-bottom",
              type: "is-danger",
              message: "קיימת חפיפת תאריכים בין החוזים.",
              duration: 8000,
            });
          } else {
            Toast.open({
              position: "is-bottom",
              type: "is-danger",
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              duration: 8000,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;

  .scollable {
    height: 60vh;
    overflow-y: auto;
  }

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 15px 10px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;

      &.invalid {
        background-color: #e9f5ff;
      }

      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }

  ::v-deep {

    .datepicker .control input,
    .datepicker .control select,
    .datepicker .timepicker .field {
      direction: ltr;
      text-align: right;
    }
  }
}
</style>
