var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogRpoertStatusManage__Component" }, [
    _c("div", { staticClass: "modal-content" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("ניהול בעלות")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogRpoertStatusManage__Component Table" },
          [
            _vm._m(0),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "Table_Field",
                  class: { invalid: _vm.$v.model.Id.$error }
                },
                [
                  !_vm.editMode
                    ? _c("span", [_vm._v(_vm._s(_vm.Ownership.Name))])
                    : _vm._e(),
                  _vm.editMode
                    ? _c(
                        "v-select",
                        {
                          attrs: {
                            dir: "rtl",
                            getOptionLabel: function(item) {
                              return (
                                (item.IdNumber || "בחר") +
                                " - " +
                                (item.Text || "פרופיל")
                              )
                            },
                            options: _vm.users,
                            clearable: true,
                            disabled: false,
                            placeholder: "בחר פרופיל",
                            reduce: function(item) {
                              return item.Value
                            }
                          },
                          model: {
                            value: _vm.$v.model.Id.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.model.Id, "$model", $$v)
                            },
                            expression: "$v.model.Id.$model"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options"
                            },
                            [_vm._v("לא נמצאו ערכים דומים")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "Table_Field",
                  class: { invalid: _vm.$v.model.StartDate.$error }
                },
                [
                  !_vm.editMode
                    ? _c(
                        "span",
                        [
                          _c("FieldDateHourInline", {
                            attrs: { value: _vm.Ownership.StartDate }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode
                    ? _c("b-datetimepicker", {
                        attrs: {
                          disabled: !_vm.editMode,
                          locale: "en-GB",
                          datepicker: {
                            monthNames: _vm.monthNames,
                            dayNames: _vm.dayNames
                          },
                          "icon-prev": "chevron-right",
                          "icon-next": "chevron-left",
                          icon: "calendar-today"
                        },
                        model: {
                          value: _vm.$v.model.StartDate.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.model.StartDate, "$model", $$v)
                          },
                          expression: "$v.model.StartDate.$model"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "Table_Field",
                  class: { invalid: _vm.$v.model.EndDate.$error }
                },
                [
                  _c("b-datetimepicker", {
                    attrs: {
                      locale: "en-GB",
                      editable: "",
                      datepicker: {
                        monthNames: _vm.monthNames,
                        dayNames: _vm.dayNames
                      },
                      "icon-prev": "chevron-right",
                      "icon-next": "chevron-left",
                      icon: "calendar-today"
                    },
                    model: {
                      value: _vm.$v.model.EndDate.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.model.EndDate, "$model", $$v)
                      },
                      expression: "$v.model.EndDate.$model"
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      ]),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "div",
          [
            _c("b-button", {
              attrs: {
                loading: _vm.isLoading,
                label: "שמור",
                type: "is-primary"
              },
              on: { click: _vm.save }
            }),
            _c("b-button", {
              attrs: { disabled: _vm.isLoading, label: "סגור" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column", staticStyle: { width: "200px" } }, [
        _vm._v("לקוח")
      ]),
      _c("div", { staticClass: "column", staticStyle: { width: "100px" } }, [
        _vm._v("מתאריך")
      ]),
      _c("div", { staticClass: "column", staticStyle: { width: "200px" } }, [
        _vm._v("עד תאריך")
      ]),
      _c("div", { staticClass: "column", staticStyle: { width: "50px" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }