<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול אופן מסירה</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogRpoertStatusManage__Component Table">
          <div class="columns">
            <div class="column" style="width: 200px">תאריך</div>
            <div class="column" style="width: 200px">אופן מסירה</div>
            <div class="column" style="width: 50px"></div>
          </div>
          <div class="row" v-for="(item, index) in model" :key="item.Date">
            <div>{{ item.Date | date }}</div>
            <div>{{ item.Origin && item.Origin.Name }}</div>
            <div>
              <a class="removeItem" href="" @click.prevent="removeItem(index)"
                >הסר</a
              >
            </div>
          </div>
          <div
            class="row"
            v-for="(item, index) in $v.newItemModel.$each.$iter"
            :key="index"
          >
            <div class="Table_Field">
              <b-datepicker
                v-model="item.Date.$model"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                icon="calendar-today"
                locale="en-GB"
                :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']"
                :month-names="[
                  'ינואר',
                  'פברואר',
                  'מרץ',
                  'אפריל',
                  'מאי',
                  'יוני',
                  'יולי',
                  'אוגוסט',
                  'ספטמבר',
                  'אוקטובר',
                  'נובמבר',
                  'דצמבר',
                ]"
              />
            </div>
            <div class="Table_Field">
              <v-select
                dir="rtl"
                label="Text"
                :options="methods"
                :clearable="true"
                placeholder="בחר אופן מסירה"
                :reduce="(item) => item.Value"
                v-model="item.Method.$model"
              >
                <div slot="no-options">לא נמצאו ערכים דומים</div>
              </v-select>
            </div>
            <div>
              <a
                class="removeItem"
                href=""
                @click.prevent="removeNewItem(index)"
                >הסר</a
              >
            </div>
          </div>
        </div>
        <h1 class="no-records" v-if="!model.length && !newItemModel.length">
          הוסף שורה כדי להוסיף אופן מסירה
        </h1>
      </section>
      <footer class="modal-card-foot">
        <div>
          <b-button
            :loading="isLoading"
            @click="save"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </div>
        <div>
          <a href="" @click.prevent="addRow">הוסף שורה</a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import DeliveryMethodsService from "@/services/DeliveryMethodsService";
import ReportService from "@/services/ReportService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
import { required } from "vuelidate/lib/validators";
import store from "../../store";
import datesValidator from "../../validators/dates";

export default {
  name: "ReportDeliveryManageWidget",
  props: ["reports"],
  components: {
    vSelect,
  },
  computed: {
    Id: () => store.state.report.Id,
    DeliveryEvents: () => store.state.report.DeliveryEvents,
  },
  validations: {
    newItemModel: {
      $each: {
        Date: {
          required,
          isValidDate: datesValidator.isValidDate,
        },
        Method: {
          required,
        },
      },
    },
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      model: [],
      methods: [],
      newItemModel: [],
    };
  },
  created() {
    DeliveryMethodsService.get().then((r) => {
      this.methods = r;
    });

    this.model = this.DeliveryEvents.slice(0);
  },
  methods: {
    addRow() {
      this.newItemModel.push({
        Date: null,
        Method: null,
      });
    },
    removeItem(index) {
      this.model.splice(index, 1);
    },
    removeNewItem(index) {
      this.newItemModel.splice(index, 1);
    },
    setSelected(value) {
      this.statusId = value;
    },
    save() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      const data = this.model.slice(0).concat(
        this.newItemModel.map((i) => ({
          DeliveryDate: i.Date,
          DeliveryMethod: { Id: i.Method },
        }))
      );
      ReportService.setDeliveryMethod(this.Id, data)
        .then((r) => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit("report/storeReportDetails", {
            DeliveryMethods: r.data,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem {
      text-decoration: underline;
    }

    & > div {
      display: table-cell;
      padding: 15px 10px;
    }

    .Table_Field {
      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }
}
</style>
