<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול התראות</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogRpoertStatusManage__Component Table">
          <div class="columns">
            <div class="column">סטטוס</div>
            <div class="column" style="width: 50px"></div>
          </div>
          <div class="row" v-for="(item, index) in model" :key="item.Date">
            <div>{{ item.Name }}</div>
            <div>
              <a class="removeItem" href="" @click.prevent="removeItem(index)"
                >הסר</a
              >
            </div>
          </div>
        </div>
        <h1 class="no-records" v-if="!model.length">אין התראות לדוח</h1>
      </section>
      <footer class="modal-card-foot">
        <div>
          <b-button
            :loading="isLoading"
            v-if="removedItems.length"
            @click="save"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import ReportService from "@/services/ReportService";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
import store from "../../store";

export default {
  name: "ReportAlertsManageWidget",
  props: ["reports"],
  computed: {
    Id: () => store.state.report.Id,
    Alerts: () => store.state.report.Alerts,
  },
  data() {
    return {
      isLoading: false,
      model: [],
      removedItems: [],
    };
  },
  created() {
    this.model = this.Alerts.slice(0);
  },
  methods: {
    removeItem(index) {
      this.removedItems.push(this.model[index]);
      this.model.splice(index, 1);
    },
    setSelected(value) {
      this.statusId = value;
    },
    save() {
      this.isLoading = true;

      if (this.removedItems.length) {
        Promise.all(
          this.removedItems.map((i) =>
            ReportService.deleteStatus(this.Id, i.Id)
          )
        )
          .then(() => {
            this.$emit("close");
            Toast.open({
              type: "is-success",
              message: "הפעולה בוצעה!",
              duration: 4000,
            });
            store.commit("report/storeReportDetails", { Alerts: this.model });
          })
          .catch(() => {
            Toast.open({
              type: "is-danger",
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              duration: 8000,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem {
      text-decoration: underline;
    }

    & > div {
      display: table-cell;
      padding: 15px 10px;
    }

    .Table_Field {
      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }
}
</style>
