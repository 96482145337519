<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול היסטורית בעלויות</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogRpoertStatusManage__Component Table">
          <div class="columns">
            <div class="column" style="width: 200px">בעלות קודמת</div>
            <div class="column" style="width: 100px">מתאריך</div>
            <div class="column" style="width: 200px">עד תאריך</div>
            <div class="column" style="width: 50px"></div>
          </div>

          <div class="row" v-for="(row, idx) in history" :key="idx">
            <div class="Table_Field">
              <!-- <pre>{{ row }}</pre> -->
              <span>{{ row.Name }}</span>
              <!-- <v-select
                v-if="editMode"
                dir="rtl"
                :getOptionLabel="(item) => `${item.Text}`"
                :options="companies"
                :clearable="true"
                :disabled="true"
                placeholder="בחר פרופיל"
                :reduce="(item) => item.Value"
                v-model="$v.model.Id.$model"
              >
                <div slot="no-options">לא נמצאו ערכים דומים</div>
              </v-select> -->
            </div>
            <div class="Table_Field">
              <span>
                <FieldDateHourInline :value="row.StartDate" />
              </span>
              <!-- <b-datepicker
                v-if="editMode"
                :disabled="true"
                locale="en-GB"
                v-model="$v.model.StartDate.$model"
                :datepicker="{ monthNames, dayNames }"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                icon="calendar-today"
              /> -->
            </div>
            <div class="Table_Field">
              <b-datetimepicker
                locale="en-GB"
                v-model="dates[idx]"
                editable
                :datepicker="{ monthNames, dayNames }"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                icon="calendar-today"
                :placeholder="presentDateFormat(row.EndDate)"
              />
            </div>
            <b-button
              style="margin: 15px 0"
              :loading="isLoading"
              @click="update(row, idx)"
              label="שמור"
              type="is-primary"
              :disabled="!isNewDateCopmare(idx)"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div>
          <!-- <b-button
            :loading="isLoading"
            @click="save"
            label="שמור"
            type="is-primary"
          /> -->
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// import LeasingCompaniesService from "@/services/LeasingCompaniesService";
// import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
// import { required } from "vuelidate/lib/validators";
import moment from "moment";
import store from "../../store";
import VehicleService from "../../services/VehicleService";
import FieldDateHourInline from "../Fields/FieldDateHourInline.vue";

export default {
  name: "VehicleAddOwnership",
  components: {
    // vSelect,
    FieldDateHourInline,
  },
  computed: {
    Id: () => store.state.vehicle.Id,
    Ownership: () => store.state.vehicle.Ownership,
    editMode: () => true,
  },
  data() {
    return {
      isLoading: false,
      //   companies: [],
      history: [],
      dates: [],
      monthNames: [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר",
      ],
      dayNames: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    };
  },
  created() {
    VehicleService.getOwnersAdmin(this.Id).then((r) => {
      this.history = r.data;
      this.dates = r.data.map((d) => new Date(d.EndDate));
    });
    // LeasingCompaniesService.get().then((r) => {
    //   this.companies = r;
    // });
  },
  methods: {
    isNewDateCopmare(idx) {
      return (
        this.dates[idx].getTime() !==
        new Date(this.history[idx].EndDate).getTime()
      );
    },
    presentDateFormat(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY, HH:mm");
      }
      return null;
    },
    getDateFormat(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DDTHH:mm");
      }
      return null;
    },
    update(row, idx) {
      VehicleService.updateOwnershipHistory(this.Id, row.VehicleOwnerId, {
        EndDate: this.getDateFormat(this.dates[idx]),
      })
        .then(() => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch((err) => {
          if (err.response?.data?.ErrorCode === 5) {
            Toast.open({
              position: "is-bottom",
              type: "is-danger",
              message: "קיימת חפיפת תאריכים עם בעלות אחרת.",
              duration: 8000,
            });
          } else {
            Toast.open({
              position: "is-bottom",
              type: "is-danger",
              message: "לא היה ניתן לעדכן את התאריך!",
              duration: 8000,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    & > div {
      display: table-cell;
      padding: 15px 10px;
      vertical-align: middle;
    }

    .Table_Field {
      &.invalid {
        background-color: #e9f5ff;
      }

      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }

  ::v-deep {
    .datepicker .control input,
    .datepicker .control select,
    .datepicker .timepicker .field {
      direction: ltr;
      text-align: right;
    }
  }
}
</style>
