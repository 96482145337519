<template>
<div class="Widget__Edit__Component">
    <div class='SideMenu__Header'>
          <h1>עריכת תשלום דוח</h1>
    </div>
    <div class='SideMenu__Content'>
            <label>
                <div>
                    <FieldInlineSelect
                        label="שולם"
                        :filterable="false"
                        :clearable="false"
                        :options="[{Value: true, Text: 'כן'},{Value: false, Text: 'לא'},]"
                        v-model="model.IsPaid"
                        :required="$v.model.IsPaid.$error" />
                </div>
            </label>

            <FieldInlineDate
                label="תאריך תשלום"
                :invalid="$v.model.PaymentDate.$invalid"
                :read-only="!model.IsPaid"
                v-model="$v.model.PaymentDate.$model" />

            <FieldInlineText
                :read-only="!model.IsPaid"
                label="אסמכתה"
                v-model="model.PaymentReferenceNumber" />

    </div>
    <div class='SideMenu__Footer'>
        <b-button :disabled="isLoading" @click="$emit('close')" class='close' type=''>
            ביטול
        </b-button>
        <b-button :loading="isLoading" @click="save()" class='create' type=''>
            שמור
        </b-button>
    </div>
</div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import ReportService from '@/services/ReportService';
import { createHelpers } from 'vuex-map-fields';
import { mapGetters, mapMutations } from 'vuex';
import { ToastProgrammatic as Toast } from 'buefy';
import { requiredIf, required } from 'vuelidate/lib/validators';

const { mapFields } = createHelpers({
  getterType: 'report/getField',
  mutationType: 'report/updateField',
});

export default {
    name: 'EditReportPaymentWidget',

    components: {
        FieldInlineText,
        FieldInlineSelect,
        FieldInlineDate,
    },
    validations: {
        model: {
            IsPaid: {
                required,
            },
            PaymentDate: {
                required: requiredIf(function () {
                    return this.model.IsPaid;
                }),
            },
        },
    },
    computed: {
         ...mapFields([
             'Id',
        ]),
        ...mapGetters('report', [
            'editPaymentModel',
        ]),
    },
    data() {
        return {
            model: {
                IsPaid: null,
                PaymentDate: null,
                PaymentReferenceNumber: null,
            },
            isLoading: false,
        };
    },
    created() {
        this.model = { ...this.model, ...this.editPaymentModel };
    },
    methods: {
        save() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;
            if (this.model.IsPaid) {
                ReportService.setPayment(this.Id, {
                    PaymentReferenceNumber: this.model.PaymentReferenceNumber,
                    PaymentDate: this.model.PaymentDate,
                }).then(() => {
                    this.setReport(this.model);
                    this.$emit('close');
                })
                .catch(() => {
                    Toast.open({
                        type: 'is-danger',
                        message: 'המערכת נתקלה בבעיה! יש לנסות שוב או לפנות לתמיכה הטכנית.',
                        position: 'is-bottom',
                        duration: 8000,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
            } else {
                ReportService.setAsUnpaid(this.Id)
                .finally(() => {
                    this.isLoading = false;
                }).then(() => {
                    this.setReport({
                        ...this.model,
                        PaymentReferenceNumber: null,
                        PaymentDate: null,
                    });
                    this.$emit('close');
                })
                .catch(() => {
                    Toast.open({
                        type: 'is-danger',
                        message: 'המערכת נתקלה בבעיה! יש לנסות שוב או לפנות לתמיכה הטכנית.',
                        position: 'is-bottom',
                        duration: 8000,
                    });
                });
            }
        },
        cancel() {
            this.restoreModel();
        },
        // getDrivers() {
        //     return DriverService.get;
        // },
        // setDriver(value) {
        //     if (value) {
        //         this.model.Driver = { ...{ Id: value.Value, Name: value.Text } };
        //     }
        // },
        ...mapMutations({
            setReport: 'report/setReport',
        }),
    },
    watch: {
        'model.IsPaid': function (newValue) {
            if (newValue === false) {
                this.model.PaymentReferenceNumber = null;
                this.model.PaymentDate = null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.Widget__Edit__Component {
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>
