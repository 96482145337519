<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול בעלות</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogRpoertStatusManage__Component Table">
          <div class="columns">
            <div class="column" style="width: 200px">לקוח</div>
            <div class="column" style="width: 100px">מתאריך</div>
            <div class="column" style="width: 200px">עד תאריך</div>
            <div class="column" style="width: 50px"></div>
          </div>

          <div class="row">
            <div class="Table_Field" :class="{ invalid: $v.model.Id.$error }">
              <span v-if="!editMode">{{ Ownership.Name }}</span>
              <v-select
                v-if="editMode"
                dir="rtl"
                :getOptionLabel="(item) => `${item.IdNumber || 'בחר'} - ${item.Text || 'פרופיל'}`"
                :options="users"
                :clearable="true"
                :disabled="false"
                placeholder="בחר פרופיל"
                :reduce="(item) => item.Value"
                v-model="$v.model.Id.$model"
              >
                <div slot="no-options">לא נמצאו ערכים דומים</div>
              </v-select>
            </div>
            <div
              class="Table_Field"
              :class="{ invalid: $v.model.StartDate.$error }"
            >
              <span v-if="!editMode">
                <FieldDateHourInline :value="Ownership.StartDate" />
              </span>
              <b-datetimepicker
                v-if="editMode"
                :disabled="!editMode"
                locale="en-GB"
                v-model="$v.model.StartDate.$model"
                :datepicker="{ monthNames, dayNames }"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                icon="calendar-today"
              />
            </div>
            <div
              class="Table_Field"
              :class="{ invalid: $v.model.EndDate.$error }"
            >
              <b-datetimepicker
                locale="en-GB"
                v-model="$v.model.EndDate.$model"
                editable
                :datepicker="{ monthNames, dayNames }"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                icon="calendar-today"
              />
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div>
          <b-button
            :loading="isLoading"
            @click="save"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import vSelect from "vue-select";
import ProfileService from "@/services/ProfileService";
import "vue-select/src/scss/vue-select.scss";
import {
  SnackbarProgrammatic as Snackbar,
  ToastProgrammatic as Toast,
} from "buefy";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import store from "../../store";
import VehicleService from "../../services/VehicleService";
import FieldDateHourInline from "../Fields/FieldDateHourInline.vue";

export default {
  name: "VehicleAddOwnership",
  components: {
    vSelect,
    FieldDateHourInline,
  },
  computed: {
    Id: () => store.state.vehicle.Id,
    Ownership: () => store.state.vehicle.Ownership,
    editMode: () => !store.state.vehicle.Ownership?.Id,
    isAdminWithoutProfileId: () => !store.state.auth.profileId && store.state.auth?.user?.claims?.isAdmin === "True"
  },
  validations: {
    model: {
      Id: {
        required,
      },
      StartDate: {
        required,
      },
      EndDate: {},
    },
  },
  data() {
    return {
      isLoading: false,
      model: {
        Name: null,
        Id: null,
        StartDate: null,
        EndDate: null,
      },
      users: [],
      monthNames: [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר",
      ],
      dayNames: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    };
  },
  created() {
    if (this.isAdminWithoutProfileId) {
        ProfileService.allMapped(false).then((r) => {
          this.users = r;
        });
      } else {
        ProfileService.allMapped(false).then((r) => {
        const res = r.filter((p) => p.IsLeasingCompany);
        this.users = res;
      });
    }

    if (!this.editMode) {
      this.model = {
        Name: this.Ownership.Name,
        Id: this.Ownership.Id,
        StartDate:
          this.Ownership.StartDate && new Date(this.Ownership.StartDate),
        EndDate: this.Ownership.EndDate && new Date(this.Ownership.EndDate),
      };
    }

    if (this.editMode) {
      this.model.Id = store.state.profile?.Id;
    }
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      if (this.editMode) {
        this.createNew();
      } else {
        this.update();
      }
    },
    createNew() {
      VehicleService.update(this.Id, {
        ...store.state.vehicle,
        Ownership: {
          Id: this.model.Id,
          StartDate: this.getDateFormat(this.model.StartDate),
          EndDate: this.getDateFormat(this.model.EndDate),
        },
      })
        .then(() => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit("vehicle/setVehicle", {
            Ownership: { ...this.Ownership, EndDate: this.model.EndDate },
          });
        })
        .catch((err) => {
          let msg = "";
          switch (err.response?.data?.ErrorCode) {
            case 60:
              // eslint-disable-next-line no-unused-expressions
              msg = "קיימת חפיפת תאריכים בין החוזים";
              break;
            case 5:
              // eslint-disable-next-line no-unused-expressions
              msg = "קיימת חפיפת תאריכים עם בעלות אחרת.";
              break;
            case 59:
              // eslint-disable-next-line no-unused-expressions
              msg = "תאריך ההתחלה הוזן אחרי תאריך הסיום";
              break;
            default:
              msg = "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.";
          }
          Snackbar.open({
            message: msg,
            type: "is-danger ",
            position: "is-bottom",
            actionText: "אישור",
            indefinite: true,
          });
          // Toast.open({
          //   position: "is-bottom",
          //   type: "is-danger",
          //   message: msg,
          //   duration: 8000,
          // });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDateFormat(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DDTHH:mm");
      }
      return null;
    },
    update() {
      VehicleService.updateOwnership(this.Id, this.Ownership.VehicleOwnerId, {
        EndDate: this.getDateFormat(this.model.EndDate),
      })
        .then(() => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit("vehicle/setVehicle", {
            Ownership: { ...this.Ownership, EndDate: this.model.EndDate },
          });
        })
        .catch((err) => {
          if (err.response?.data?.ErrorCode === 60) {
            Toast.open({
              position: "is-bottom",
              type: "is-danger",
              message: "קיימת חפיפת תאריכים עם בעלות אחרת.",
              duration: 8000,
            });
          } else {
            // Toast.open({
            //   position: "is-bottom",
            //   type: "is-danger",
            //   message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            //   duration: 8000,
            // });
            Snackbar.open({
              message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
              type: "is-danger ",
              position: "is-bottom",
              actionText: "אישור",
              indefinite: true,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    & > div {
      display: table-cell;
      padding: 15px 10px;
      vertical-align: middle;
    }

    .Table_Field {
      &.invalid {
        background-color: #e9f5ff;
      }

      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }

  ::v-deep {
    .datepicker .control input,
    .datepicker .control select,
    .datepicker .timepicker .field {
      direction: ltr;
      text-align: right;
    }
  }
}
</style>
