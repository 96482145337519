<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול חוזים</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="scollable">
          <div class="DialogRpoertStatusManage__Component Table">
            <div class="columns">
              <div class="column" style="width: 200px">רכב</div>
              <div class="column" style="width: 200px">מתאריך</div>
              <div class="column" style="width: 200px">עד תאריך</div>
              <div class="column" style="width: 50px"></div>
            </div>
            <div class="row" v-for="(item, index) in model" :key="item.Id">
              <div class="Table_Field">{{ item.PlateNumber }}</div>
              <div class="Table_Field datetime_format">
                <FieldInlineDateTime v-model="item.StartDate" />
                <!-- <FieldInlineDateTime :value="item.StartDate" /> -->
              </div>
              <div class="Table_Field datetime_format">
                <FieldInlineDateTime v-model="item.EndDate" />
                <!-- <FieldInlineDateTime :value="item.EndDate" /> -->
              </div>
              <div>
                <a class="updateItem" href="" @click.prevent="updateItem(index)"
                  >עדכן</a
                >
                <a class="removeItem" href="" @click.prevent="removeItem(index)"
                  >הסר</a
                >
              </div>
            </div>

            <div
              class="row"
              v-for="(item, index) in $v.newItemModel.$each.$iter"
              :key="index"
            >
              <div class="Table_Field" :class="{ invalid: item.Id.$error }">
                <v-select
                  dir="rtl"
                  :getOptionLabel="(item) => `${item.PlateNumber}`"
                  :options="vehicles"
                  :clearable="true"
                  placeholder="בחר רכב"
                  :reduce="(item) => item.Id"
                  v-model="item.Id.$model"
                  :loading="isVehiclesLoading"
                  :filter="filter"
                >
                  <!-- :map-keydown="handlers" -->
                  <div slot="no-options">לא נמצאו ערכים דומים</div>
                </v-select>
              </div>
              <div
                class="Table_Field"
                :class="{ invalid: item.StartDate.$error }"
              >
                <b-datetimepicker
                  locale="en-GB"
                  v-model="item.StartDate.$model"
                  editable
                  :datepicker="{ monthNames, dayNames }"
                  icon-prev="chevron-right"
                  icon-next="chevron-left"
                  icon="calendar-today"
                />
              </div>
              <div
                class="Table_Field"
                :class="{ invalid: item.EndDate.$error }"
              >
                <b-datetimepicker
                  locale="en-GB"
                  v-model="item.EndDate.$model"
                  editable
                  :datepicker="{ monthNames, dayNames }"
                  icon-prev="chevron-right"
                  icon-next="chevron-left"
                  icon="calendar-today"
                />
              </div>
              <div>
                <a
                  class="removeItem"
                  href=""
                  @click.prevent="removeNewItem(index)"
                  >הסר</a
                >
              </div>
            </div>
          </div>
        </div>
        <h1 class="no-records" v-if="!model.length && !newItemModel.length">
          הוסף שורה כדי להוסיף חוזה לנהג
        </h1>
      </section>
      <footer class="modal-card-foot">
        <div>
          <b-button
            :loading="isLoading"
            @click="save"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </div>
        <div>
          <a href="" @click.prevent="addRow">הוסף שורה</a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import VehicleService from "@/services/VehicleService";
import DriverService from "../../services/DriverService";
import FieldInlineDateTime from "../Fields/FieldInlineDateTime.vue";
import store from "../../store";

export default {
  name: "DriverAddContract",
  components: {
    vSelect,
    FieldInlineDateTime,
  },
  computed: {
    DriverId: () => store.state.driver.Id,
    VehicleContracts: () => store.state.driver.VehicleContracts,
    isAdmin: () => store.state.auth?.user?.claims?.isAdmin === "True",
  },
  validations: {
    newItemModel: {
      $each: {
        Id: {
          required,
        },
        StartDate: {
          required,
        },
        EndDate: {
          required,
        },
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isVehiclesLoading: false,
      model: [],
      vehicles: [],
      newItemModel: [],
      monthNames: [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר",
      ],
      dayNames: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    };
  },
  async created() {
    this.getAllVehicles();
    if (this.VehicleContracts) {
      this.model = this.VehicleContracts.slice(0);
    }
  },
  methods: {
    filter(options, search) {
      const newOptions = options.filter((option) => {
        const label = option.PlateNumber;
        return label.includes(search.trim());
        // return label.includes(search.match(/[^\s]+/g));
      });
      return newOptions;
    },
    // handlers: (map, vm) => ({
    //   ...map,
    //   32: (e) => {
    //     console.log("vm:", vm);
    //     e.preventDefault();
    //   },
    // }),
    addRow() {
      this.newItemModel.push({
        Id: null,
        StartDate: null,
        EndDate: null,
      });
    },
    getAllVehicles() {
      this.isVehiclesLoading = true;
      VehicleService.search({
        IsAscending: false,
        PageNumber: 1,
        PageSize: 99999,
        SortedBy: "PlateNumber",
        fromDate: "2000-01-01",
      })
        .then((r) => {
          const { data } = r;
          this.vehicles = data.Items.slice(0);
        })
        .catch((error) => {
          this.vehicles = [];
          throw error;
        })
        .finally(() => {
          this.isVehiclesLoading = false;
        });
    },
    getDateFormat(value) {
      if (value) {
        return moment(value).format("YYYY-MM-DDTHH:mm");
      }
      return null;
    },
    removeItem(index) {
      DriverService.deleteContract(this.DriverId, this.model[index].Id);
      this.model.splice(index, 1);
      store.commit("driver/setVehicleContracts", this.model);
    },
    updateItem(index) {
      DriverService.updateContract(this.DriverId, this.model[index])
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit("driver/setVehicleContracts", this.model);
        })
        .catch((err) => {
          let msg = "";
          let driverName;
          let res;
          switch (err.response?.data?.ErrorCode) {
            case 5:
            case 32:
              // eslint-disable-next-line no-unused-expressions
              msg = "קיימת חפיפת תאריכים בין החוזים";
              break;
            case 52:
            case 59:
              // eslint-disable-next-line no-unused-expressions
              msg = "תאריך ההתחלה הוזן אחרי תאריך הסיום";
              break;
            case 29:
              // eslint-disable-next-line no-unused-expressions
              msg = "נהג לא קיים";
              break;
            case 33:
              // eslint-disable-next-line no-unused-expressions
              msg = "רכב לא קיים עבור הפרופיל הזה";
              break;
            case 61:
              // eslint-disable-next-line no-unused-expressions
              res = err.response.statusText.split(":");
              driverName = res[res.length - 1];
              msg = `רכב זה כבר בחוזה עם הנהג${driverName} בתאריכים אלו`;
              break;

            default:
              msg = "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.";
          }
          Toast.open({
            position: "is-bottom",
            type: "is-danger",
            message: msg,
            duration: 8000,
          });
        });
    },
    removeNewItem(index) {
      this.newItemModel.splice(index, 1);
    },
    setSelected(value) {
      this.statusId = value;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      if (!this.newItemModel.length) {
        store.commit("driver/setVehicleContracts", this.model);
        this.$emit("close");
        return;
      }

      const reqs = this.newItemModel.map((i) =>
        DriverService.addContract(this.DriverId, {
          VehicleId: i.Id,
          StartDate: this.getDateFormat(i.StartDate),
          EndDate: this.getDateFormat(i.EndDate),
        })
      );
      Promise.all(reqs)
        .then((r) => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit(
            "driver/setVehicleContracts",
            this.model.concat(r.map((i) => i.data))
          );
        })
        .catch((err) => {
          let msg = "";
          let driverName;
          let res;
          switch (err.response?.data?.ErrorCode) {
            case 5:
            case 32:
              // eslint-disable-next-line no-unused-expressions
              msg = "קיימת חפיפת תאריכים בין החוזים";
              break;
            case 52:
            case 59:
              // eslint-disable-next-line no-unused-expressions
              msg = "תאריך ההתחלה הוזן אחרי תאריך הסיום";
              break;
            case 29:
              // eslint-disable-next-line no-unused-expressions
              msg = "נהג לא קיים";
              break;
            case 33:
              // eslint-disable-next-line no-unused-expressions
              msg = "רכב לא קיים עבור הפרופיל הזה";
              break;
            case 61:
              // eslint-disable-next-line no-unused-expressions
              res = err.response.data.ErrorMessage.split(":");
              driverName = res[res.length - 1];
              msg = `רכב זה כבר בחוזה עם הנהג${driverName} בתאריכים אלו`;
              break;

            default:
              msg = "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.";
          }
          Toast.open({
            position: "is-bottom",
            type: "is-danger",
            message: msg,
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;

  .scollable {
    height: 60vh;
    overflow-y: auto;
  }

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
      margin-left: 10px;
    }

    & > div {
      display: table-cell;
      padding: 15px 10px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;

      &.invalid {
        background-color: #e9f5ff;
      }

      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }

  ::v-deep {
    .datepicker .control input,
    .datepicker .control select,
    .datepicker .timepicker .field {
      direction: ltr;
      text-align: right;
    }
  }
}
</style>
